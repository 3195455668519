<template>
    <div>
        <el-card style="margin-top: 8px">
            <div slot="header" class="card-header">
                <span>新门店导入前系统数据</span>
            </div>

            <!-- 添加可售商品-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button type="text" @click="jumpToGoods" class="font1" :disabled="!hasCreateGoodsPrivilege">
                            添加可售商品
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <el-tooltip
                            class="font2"
                            style="text-decoration: underline"
                            :content="roleNamesForCreateGoods"
                            placement="top"
                        >
                            <span>此类角色</span>
                        </el-tooltip>
                        可添加可售商品
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button type="text" class="font2" @click="jumpToGoods" :disabled="!hasCreateGoodsPrivilege"
                            >①手动添加可售商品</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>首家门店点击新建-选择机构名称-点击选择商品基本信息-勾选需要的商品-点击保存</span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button type="text" class="font2" @click="jumpToGoods" :disabled="!hasCreateGoodsPrivilege"
                            >②复制可售商品</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        如之前已有门店点击复制商品-选择源机构和机构名称-点击选择商品基本信息-勾选需要的商品-点击保存
                    </el-col>
                </el-row>
            </el-card>

            <!--初始化数据-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button
                            type="text"
                            @click="jumpToSysIn"
                            class="font1"
                            :disabled="!hasCreatePurchaseSysInPrivilege"
                            >初始化数据</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <span>
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForCreatePurchaseSysIn"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可进行商品的库存，零售价的设置</span>
                        </span>
                        <span style="padding-left: 100px">
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForReviewPurchaseSysIn"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可进行审核</span>
                        </span>
                        <span style="padding-left: 100px">
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForStockPurchaseSysIn"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可出/入库</span>
                        </span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToSysIn"
                            :disabled="!hasCreatePurchaseSysInPrivilege"
                            >①下载初始化数据表格</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            点击新建-选择门店名称-点击导出可售商品数据-在EXCEL中填写商品的库存、批发价、零售价 -
                            保存EXCEL后点击导入-选择修改后的EXCEL文件-导入成功后勾选此数据点击审核-审核通过后再次勾选此数据点击入库
                        </span>
                    </el-col>
                </el-row>
            </el-card>

            <!--商品上架-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button type="text" @click="jumpToGoods" class="font1" :disabled="!hasEditGoodsPrivilege">
                            商品上架
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <span>
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForEditGoods"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可进行商品的上架</span>
                        </span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button type="text" class="font2" @click="jumpToGoods" :disabled="!hasEditGoodsPrivilege"
                            >①批量选择商品上架</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            选择机构名称-选择所有需要上架的商品-点击批量操作-批量上架-上架成功即可在收银机上销售该商品
                        </span>
                    </el-col>
                </el-row>
            </el-card>
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';

const goodsPrivilegeFlag = 'menu.goods.goods';
const createGoodsPrivilegeFlag = `${goodsPrivilegeFlag}.create`;
const editGoodsPrivilegeFlag = `${goodsPrivilegeFlag}.edit`;

const purchaseSysInPrivilegeFlag = 'menu.purchase.sysIn';
const createPurchaseSysInPrivilegeFlag = `${purchaseSysInPrivilegeFlag}.create`;
const reviewPurchaseSysInPrivilegeFlag = `${purchaseSysInPrivilegeFlag}.review`;
const stockPurchaseSysInPrivilegeFlag = `${purchaseSysInPrivilegeFlag}.stock`;

export default {
    name: 'DeptOperateGoodsGuide',
    data() {
        return {
            rolesForCreateGoods: [],
            rolesForEditGoods: [],
            rolesForCreatePurchaseSysIn: [],
            rolesForReviewPurchaseSysIn: [],
            rolesForStockPurchaseSysIn: [],
        };
    },
    created() {
        //查询能够创建商品的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(createGoodsPrivilegeFlag).then((rst) => {
            this.rolesForCreateGoods = rst;
        });
        //查询能够编辑商品的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(editGoodsPrivilegeFlag).then((rst) => {
            this.rolesForEditGoods = rst;
        });
        //查询能够新建初始化数据的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(createPurchaseSysInPrivilegeFlag).then((rst) => {
            this.rolesForCreatePurchaseSysIn = rst;
        });
        //查询能够审核初始化数据的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(reviewPurchaseSysInPrivilegeFlag).then((rst) => {
            this.rolesForReviewPurchaseSysIn = rst;
        });
        //查询能够出入库初始化数据的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(stockPurchaseSysInPrivilegeFlag).then((rst) => {
            this.rolesForStockPurchaseSysIn = rst;
        });
    },
    computed: {
        roleNamesForCreateGoods() {
            return this.rolesForCreateGoods.map((e) => e.name).join('，');
        },
        roleNamesForEditGoods() {
            return this.rolesForEditGoods.map((e) => e.name).join('，');
        },
        roleNamesForCreatePurchaseSysIn() {
            return this.rolesForCreatePurchaseSysIn.map((e) => e.name).join('，');
        },
        roleNamesForReviewPurchaseSysIn() {
            return this.rolesForReviewPurchaseSysIn.map((e) => e.name).join('，');
        },
        roleNamesForStockPurchaseSysIn() {
            return this.rolesForStockPurchaseSysIn.map((e) => e.name).join('，');
        },
    },

    methods: {
        jumpToGoods() {
            Util.nameJump(this, goodsPrivilegeFlag);
        },
        jumpToSysIn() {
            Util.nameJump(this, purchaseSysInPrivilegeFlag);
        },
        hasCreateGoodsPrivilege() {
            return this.hasPrivilege(createGoodsPrivilegeFlag);
        },
        hasCreatePurchaseSysInPrivilege() {
            return this.hasPrivilege(createPurchaseSysInPrivilegeFlag);
        },
        hasEditGoodsPrivilege() {
            return this.hasPrivilege(editGoodsPrivilegeFlag);
        },
    },
};
</script>

<style scoped>
.font1 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: bold;
    text-decoration: underline;
    color: #36bf5d;
    line-height: 28px;
}

.font2 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #606266;
    line-height: 20px;
}

.font3 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #9ca1ab;
    line-height: 20px;
}
.title {
    background: #d6ffe2;
    border-radius: 4px 4px 0 0;
}

.content1 {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content2 {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.el-row {
    height: 70px;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    color: #c0c4cc;
    cursor: not-allowed;
}

/deep/ .el-card__body {
    padding-top: 0;
}

.card-header {
    font-size: 20px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #212121;
    line-height: 28px;
}
</style>
